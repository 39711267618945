import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Img from 'gatsby-image';
import clsx from 'clsx';
import LiveChat from 'react-livechat';
import { graphql, useStaticQuery } from 'gatsby';
import { createStructuredSelector } from 'reselect';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import { registerUser, setLoader } from '../store/actions';
import { getInstanceName, getLiveChatParams, isUserLogged } from '../helpers/utils';
import styles from '../styles/pages/verifications.module.scss';
import { selectEntityUserDetails } from '../store/selectors/entities';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import { ButtonLoaderTypes } from '../constants/types';

const query = graphql`
  query {
    verifyIcon: file(relativePath: { eq: "verify-success.png" }) {
      childImageSharp {
        fixed(width: 162) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const stateSelector = createStructuredSelector({
  userDetails: selectEntityUserDetails,
});

const RegistrationSuccessPage = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(query);
  const siteName = getInstanceName();
  const liveChatRef = useRef(null);
  const { userDetails } = useSelector(stateSelector);
  const liveChatParams = getLiveChatParams(userDetails, undefined);

  const onNextClick = () => {
    dispatch(registerUser(true));
    createNavigateTo(pageLinks.simpleCheckout)();
    dispatch(setLoader(false));
  };

  return (
    <>
      <SEO title={`Verification Success - ${siteName}`} />
      <Layout hideHeader fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles.relative}>
            <BackgroundImage
              Tag="div"
              className={styles.summary_bg_overlay}
              fluid={[data.bgLeft.childImageSharp.fluid, data.bgRight.childImageSharp.fluid]}
              backgroundColor="transparent"
            />

            <>
              <div className={clsx(styles.wrapper, styles.verifySuccess)}>
                <div className={styles.title}>Verification Success</div>

                <div className={clsx(styles.textInfo, styles.long)}>
                  Thank you for your support, we have successfully verified your mobile number.
                </div>

                <div className={styles.img}>
                  <Img fixed={data.verifyIcon.childImageSharp.fixed} />
                </div>
              </div>
            </>
          </div>
        </div>

        <div className={styles.nextButton}>
          <Button
            className={styles.button}
            size="large"
            onClick={onNextClick}
            loaderType={ButtonLoaderTypes.PURCHASE}
          >
            Next
          </Button>
        </div>

        {isUserLogged() && (
          <LiveChat
            visitor={{
              name: `${userDetails?.firstName} ${userDetails?.lastName}`,
              email: userDetails?.email,
            }}
            license={process.env.GATSBY_LIVECHAT_LICENSE}
            onChatLoaded={(ref) => {
              liveChatRef.current = ref;
            }}
            params={liveChatParams}
          />
        )}
      </Layout>
    </>
  );
};

export default RegistrationSuccessPage;
